import React from "react"

const Header1 = ({ title }) => {
  return (
    <div className="page-title-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header1
